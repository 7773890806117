<template>
	<div>
		Logout
	</div>
</template>
<script>
export default {
    created() {
        localStorage.clear();
        this.$router.push('/')
        this.$store.commit('removeUser')
	}
}
</script>